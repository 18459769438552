import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as audiomsg94TB1YOW0UMeta } from "/opt/buildhome/repo/pages/audiomsg.vue?macro=true";
import { default as callSdqMQiAvuDMeta } from "/opt/buildhome/repo/pages/call.vue?macro=true";
import { default as emailJGrUNw9UwVMeta } from "/opt/buildhome/repo/pages/email.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as keysuTBZEBvWAXMeta } from "/opt/buildhome/repo/pages/keys.vue?macro=true";
import { default as onourmindsxYoeIUGTYiMeta } from "/opt/buildhome/repo/pages/onourminds.vue?macro=true";
import { default as videomsgZED4kBiGZoMeta } from "/opt/buildhome/repo/pages/videomsg.vue?macro=true";
export default [
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___sv",
    path: aboutcGvMViY0SnMeta?.path ?? "/sv/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___en___default",
    path: aboutcGvMViY0SnMeta?.path ?? "/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___en",
    path: aboutcGvMViY0SnMeta?.path ?? "/en/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: audiomsg94TB1YOW0UMeta?.name ?? "audiomsg___sv",
    path: audiomsg94TB1YOW0UMeta?.path ?? "/sv/audiomsg",
    meta: audiomsg94TB1YOW0UMeta || {},
    alias: audiomsg94TB1YOW0UMeta?.alias || [],
    redirect: audiomsg94TB1YOW0UMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/audiomsg.vue").then(m => m.default || m)
  },
  {
    name: audiomsg94TB1YOW0UMeta?.name ?? "audiomsg___en___default",
    path: audiomsg94TB1YOW0UMeta?.path ?? "/audiomsg",
    meta: audiomsg94TB1YOW0UMeta || {},
    alias: audiomsg94TB1YOW0UMeta?.alias || [],
    redirect: audiomsg94TB1YOW0UMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/audiomsg.vue").then(m => m.default || m)
  },
  {
    name: audiomsg94TB1YOW0UMeta?.name ?? "audiomsg___en",
    path: audiomsg94TB1YOW0UMeta?.path ?? "/en/audiomsg",
    meta: audiomsg94TB1YOW0UMeta || {},
    alias: audiomsg94TB1YOW0UMeta?.alias || [],
    redirect: audiomsg94TB1YOW0UMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/audiomsg.vue").then(m => m.default || m)
  },
  {
    name: callSdqMQiAvuDMeta?.name ?? "call___sv",
    path: callSdqMQiAvuDMeta?.path ?? "/sv/call",
    meta: callSdqMQiAvuDMeta || {},
    alias: callSdqMQiAvuDMeta?.alias || [],
    redirect: callSdqMQiAvuDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/call.vue").then(m => m.default || m)
  },
  {
    name: callSdqMQiAvuDMeta?.name ?? "call___en___default",
    path: callSdqMQiAvuDMeta?.path ?? "/call",
    meta: callSdqMQiAvuDMeta || {},
    alias: callSdqMQiAvuDMeta?.alias || [],
    redirect: callSdqMQiAvuDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/call.vue").then(m => m.default || m)
  },
  {
    name: callSdqMQiAvuDMeta?.name ?? "call___en",
    path: callSdqMQiAvuDMeta?.path ?? "/en/call",
    meta: callSdqMQiAvuDMeta || {},
    alias: callSdqMQiAvuDMeta?.alias || [],
    redirect: callSdqMQiAvuDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/call.vue").then(m => m.default || m)
  },
  {
    name: emailJGrUNw9UwVMeta?.name ?? "email___sv",
    path: emailJGrUNw9UwVMeta?.path ?? "/sv/email",
    meta: emailJGrUNw9UwVMeta || {},
    alias: emailJGrUNw9UwVMeta?.alias || [],
    redirect: emailJGrUNw9UwVMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/email.vue").then(m => m.default || m)
  },
  {
    name: emailJGrUNw9UwVMeta?.name ?? "email___en___default",
    path: emailJGrUNw9UwVMeta?.path ?? "/email",
    meta: emailJGrUNw9UwVMeta || {},
    alias: emailJGrUNw9UwVMeta?.alias || [],
    redirect: emailJGrUNw9UwVMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/email.vue").then(m => m.default || m)
  },
  {
    name: emailJGrUNw9UwVMeta?.name ?? "email___en",
    path: emailJGrUNw9UwVMeta?.path ?? "/en/email",
    meta: emailJGrUNw9UwVMeta || {},
    alias: emailJGrUNw9UwVMeta?.alias || [],
    redirect: emailJGrUNw9UwVMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/email.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___sv",
    path: indexniDiYCWjuTMeta?.path ?? "/sv",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___en___default",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___en",
    path: indexniDiYCWjuTMeta?.path ?? "/en",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: keysuTBZEBvWAXMeta?.name ?? "keys___sv",
    path: keysuTBZEBvWAXMeta?.path ?? "/sv/keys",
    meta: keysuTBZEBvWAXMeta || {},
    alias: keysuTBZEBvWAXMeta?.alias || [],
    redirect: keysuTBZEBvWAXMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/keys.vue").then(m => m.default || m)
  },
  {
    name: keysuTBZEBvWAXMeta?.name ?? "keys___en___default",
    path: keysuTBZEBvWAXMeta?.path ?? "/keys",
    meta: keysuTBZEBvWAXMeta || {},
    alias: keysuTBZEBvWAXMeta?.alias || [],
    redirect: keysuTBZEBvWAXMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/keys.vue").then(m => m.default || m)
  },
  {
    name: keysuTBZEBvWAXMeta?.name ?? "keys___en",
    path: keysuTBZEBvWAXMeta?.path ?? "/en/keys",
    meta: keysuTBZEBvWAXMeta || {},
    alias: keysuTBZEBvWAXMeta?.alias || [],
    redirect: keysuTBZEBvWAXMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/keys.vue").then(m => m.default || m)
  },
  {
    name: onourmindsxYoeIUGTYiMeta?.name ?? "onourminds___sv",
    path: onourmindsxYoeIUGTYiMeta?.path ?? "/sv/onourminds",
    meta: onourmindsxYoeIUGTYiMeta || {},
    alias: onourmindsxYoeIUGTYiMeta?.alias || [],
    redirect: onourmindsxYoeIUGTYiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/onourminds.vue").then(m => m.default || m)
  },
  {
    name: onourmindsxYoeIUGTYiMeta?.name ?? "onourminds___en___default",
    path: onourmindsxYoeIUGTYiMeta?.path ?? "/onourminds",
    meta: onourmindsxYoeIUGTYiMeta || {},
    alias: onourmindsxYoeIUGTYiMeta?.alias || [],
    redirect: onourmindsxYoeIUGTYiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/onourminds.vue").then(m => m.default || m)
  },
  {
    name: onourmindsxYoeIUGTYiMeta?.name ?? "onourminds___en",
    path: onourmindsxYoeIUGTYiMeta?.path ?? "/en/onourminds",
    meta: onourmindsxYoeIUGTYiMeta || {},
    alias: onourmindsxYoeIUGTYiMeta?.alias || [],
    redirect: onourmindsxYoeIUGTYiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/onourminds.vue").then(m => m.default || m)
  },
  {
    name: videomsgZED4kBiGZoMeta?.name ?? "videomsg___sv",
    path: videomsgZED4kBiGZoMeta?.path ?? "/sv/videomsg",
    meta: videomsgZED4kBiGZoMeta || {},
    alias: videomsgZED4kBiGZoMeta?.alias || [],
    redirect: videomsgZED4kBiGZoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/videomsg.vue").then(m => m.default || m)
  },
  {
    name: videomsgZED4kBiGZoMeta?.name ?? "videomsg___en___default",
    path: videomsgZED4kBiGZoMeta?.path ?? "/videomsg",
    meta: videomsgZED4kBiGZoMeta || {},
    alias: videomsgZED4kBiGZoMeta?.alias || [],
    redirect: videomsgZED4kBiGZoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/videomsg.vue").then(m => m.default || m)
  },
  {
    name: videomsgZED4kBiGZoMeta?.name ?? "videomsg___en",
    path: videomsgZED4kBiGZoMeta?.path ?? "/en/videomsg",
    meta: videomsgZED4kBiGZoMeta || {},
    alias: videomsgZED4kBiGZoMeta?.alias || [],
    redirect: videomsgZED4kBiGZoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/videomsg.vue").then(m => m.default || m)
  }
]